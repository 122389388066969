import { useMediaQuery } from "@mui/material";
import Image from "next/image";
import React from "react";
import { PHONE_TABLET_MEDIA_QUERY } from "../../constants/breakpoints";
import {
  CustomIcon,
  CustomImg,
  SolitaireMainImg,
  SolitaireImgContainer,
  SolitaireText,
  SolitaireTextWrapper,
  SolitaireTitle,
  SolitaireWrapper,
  SolitaireContainer,
  UnderDevelopment,
} from "./styles";

const SpiderSolitaire = () => {
  const isMobile = useMediaQuery(PHONE_TABLET_MEDIA_QUERY);
  return (
    <SolitaireWrapper>
      <SolitaireContainer>
        <Image
          width={isMobile ? "182" : "252"}
          height={isMobile ? "182" : "252"}
          loading="lazy"
          src="/images/Solitaire_icon.webp"
          alt="Sudoku icon"
        />
        <SolitaireTextWrapper>
          <SolitaireTitle>Spider Solitaire</SolitaireTitle>
          <SolitaireText>
            Spider Solitaire - the original rules of one of the most popular
            classic card games, together with a nice design and a set of
            convenient functions.
          </SolitaireText>
          <UnderDevelopment>
            Under Development...
            <CustomIcon
              loading="lazy"
              width="40"
              height="40"
              src="/images/technolohist_icon.png"
              alt="technolohist icon"
            />
          </UnderDevelopment>
        </SolitaireTextWrapper>
      </SolitaireContainer>
      <SolitaireImgContainer>
        {!isMobile ? (
          <SolitaireMainImg
            loading="lazy"
            src="/images/solitaire_img_mob.webp"
            alt="Solitaire Big img"
          />
        ) : (
          <>
            <SolitaireMainImg
              loading="lazy"
              src="/images/solitaire_img_desc.webp"
              alt="Solitaire Big img"
            />
          </>
        )}
      </SolitaireImgContainer>
    </SolitaireWrapper>
  );
};

export default SpiderSolitaire;
