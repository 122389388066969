import { DESKTOP_MEDIA_QUERY } from "../../constants/breakpoints";
import * as Props from "../../types/PropsTypes";
import useMediaQuery from "../../utils/useMediaQuery";
import { StyledImg, StyledLink } from "../common/Common.components";
import styles from "./AboutOmiPlay.module.scss";

export const AboutContainer: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.aboutContainer}>{children}</div>
);

export const WhoWeAre: React.FC<Props.Base> = ({ children }) => (
  <div id="we_are_anchor" className={styles.whoWeAre}>
    {children}
  </div>
);

export const WeTitle: React.FC<Props.Base> = ({ children }) => (
  <h2 className={styles.weTitle}>{children}</h2>
);

export const GamePadImg: React.FC = () => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  return (
    <StyledImg
      src="/images/GamePad.webp"
      alt="GamePad"
      width={isDesktop ? 520 : 303}
      height={isDesktop ? 364 : 212}
      className={styles.gamePadImg}
    />
  );
};

export const AboutBlock: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.aboutBlock}>{children}</div>
);

export const AboutTitle: React.FC<Props.Base> = ({ children }) => (
  <h3 className={styles.aboutTitle}>{children}</h3>
);

export const AboutText: React.FC<Props.Base> = ({ children }) => (
  <p className={styles.aboutText}>{children}</p>
);

export const SudokuProLink: React.FC = () => (
  <StyledLink
    className={styles.sudokuProLink}
    ariaLabel="Go to SudokuPro"
    href="https://sudokupro.app/"
    rel
    target="_blank"
  >
    Play Sudoku
  </StyledLink>
);
