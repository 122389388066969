import React, { Suspense } from "react";
import { ScrollDownIcon } from "../../svg";
import Container from "../Container";
import {
  ColoredText,
  CreateIcon,
  FireIcon,
  GamesRound,
  IconWrapper,
  MainText,
  MetaballsWrapper,
  ScrollBtn,
  SubContainer,
  SubText,
} from "./styles";
import COLORS from "../../constants/colors";
import { isWindow } from "../../utils/browserCheck";
const Metaballs = React.lazy(() => import("../../helpers/Metaballs"));

const TopContent = () => {
  let anchor: HTMLElement | null;
  if (isWindow()) {
    anchor = document.getElementById("we_are_anchor");
  }
  const scrollTo = () => {
    anchor?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container>
      <MetaballsWrapper>
        <Suspense>
          <Metaballs
            numMetaballs={30}
            minRadius={3}
            maxRadius={7.5}
            speed={10.0}
            color={COLORS.MAIN}
            backgroundColor={COLORS.WHITE}
            className='balls'
          />
        </Suspense>
      </MetaballsWrapper>
      <MainText>
        We
        <IconWrapper>
          <CreateIcon /> create
        </IconWrapper>
        <br /> web & mobile
        <ColoredText>
          <GamesRound /> games
        </ColoredText>
        <br /> that simply
        <IconWrapper>
          <FireIcon /> rock
        </IconWrapper>
      </MainText>
      <SubContainer>
        <SubText>
          OmiPlay team is a game developing company based in Ukraine. Together
          we are the creators of puzzles and logic games, which are
          distinguished by a thoughtful and modern interfaces
        </SubText>
        <ScrollBtn onClick={scrollTo}>
          Scroll to explore <ScrollDownIcon />
        </ScrollBtn>
      </SubContainer>
    </Container>
  );
};

export default TopContent;
