import { styled } from "@mui/material";
import {
  desktop,
  desktop_plus,
  phone_tablet,
  tablet,
} from "../../constants/breakpoints";
import COLORS from "../../constants/colors";

export const SolitaireWrapper = styled("div")(({ theme, className }) => ({
  background: COLORS.SOLITAITE_BG,
  paddingTop: 63,
  ...tablet({
    paddingTop: 130,
  }),
}));

export const UnderDevelopment = styled("span")(({ theme, className }) => ({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: 1.2,
  letterSpacing: 0.1,
  color: COLORS.WHITE,
  textAlign: "center",
  textTransform: "uppercase",
  ...tablet({
    fontSize: 30,
  }),
}));

export const SolitaireContainer = styled("div")(({ theme, className }) => ({
  padding: "0 25px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  ...tablet({
    flexDirection: "row",
  }),
  ...desktop_plus({
    padding: 0,
    width: "calc((100%/6) * 4)",
  }),
}));

export const CustomIcon = styled("img")(({ theme, width, height }) => ({
  width: width,
  height: height,
}));

export const CustomImg = styled("img")(({ theme, width, height }) => ({
  width: 182,
  height: 182,
  ...tablet({
    width: 252,
    height: 252,
  }),
}));

export const SolitaireText = styled("p")(({ theme, className }) => ({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: 1.4,
  textTransform: "uppercase",
  color: COLORS.WHITE,
  textAlign: "center",
  marginBottom: 31,
  ...tablet({
    textAlign: "left",
  }),
}));

export const SolitaireTitle = styled("h3")(({ theme, className }) => ({
  fontWeight: 700,
  fontSize: 30,
  lineHeight: 1.2,
  textTransform: "uppercase",
  color: COLORS.WHITE,
  textAlign: "center",
  marginBottom: 20,
  marginTop: 0,
  ...tablet({
    marginBottom: 30,
    textAlign: "left",
    fontSize: 48,
  }),
}));

export const SolitaireTextWrapper = styled("div")(({ theme, className }) => ({
  textAlign: "center",
  ...tablet({
    textAlign: "left",
  }),
}));

export const SolitaireImgContainer = styled("div")(({ theme, className }) => ({
  lineHeight: 0.5,
}));

export const SolitaireMainImg = styled("img")(({ theme, width, height }) => ({
  width: "100%",
}));
