import React from "react";
import { ColoredText } from "../common/styles";
import {
  AboutBlock,
  AboutContainer,
  GamePadImg,
  WeTitle,
  WhoWeAre,
  AboutTitle,
  AboutText,
  SudokuProLink,
} from "./AboutOmiPlay.components";

const AboutOmiPlay = () => {
  return (
    <AboutContainer>
      <WhoWeAre>
        <WeTitle>
          WHO WE A<ColoredText>RE</ColoredText>
        </WeTitle>
        <GamePadImg />
      </WhoWeAre>
      <AboutBlock>
        <AboutTitle>About Omiplay</AboutTitle>
        <AboutText>
          We are a team of dreamers who have come together to create the best
          games that are fun first and foremost. We believe that when we love
          what we do, others will love what we do.
        </AboutText>
        <AboutText>
          We are an offshoot of the OmiSoft team, which was founded in 2017 and
          is a professional product development company. Therefore, we have
          adopted their best practices and continue to exchange experience.
        </AboutText>
        <AboutText>
          We are the developers of puzzles and logic games such as{" "}
          <SudokuProLink />, Spider, Spider Solitaire and others.
        </AboutText>
      </AboutBlock>
    </AboutContainer>
  );
};

export default AboutOmiPlay;
