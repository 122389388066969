import { styled } from "@mui/material";
import { desktop, desktop_plus, tablet } from "../../constants/breakpoints";

export const WarTitle = styled("h2")(({ theme, className }) => ({
  fontWeight: 700,
  fontSize: 26,
  lineHeight: 1.4,
  textTransform: "uppercase",
  paddingLeft: className?.includes("second-part") ? 58 : 0,
  margin: 0,
  ...desktop({
    paddingLeft: className?.includes("second-part") ? 110 : 0,
    maxWidth: 1000,
    fontSize: 60,
    lineHeight: 1.3,
  }),
}));

export const WarText = styled("p")(({ theme, className }) => ({
  maxWidth: 520,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1.6,
  marginBottom: 15,
  ...tablet({
    maxWidth: 800,
    marginBottom: 30,
    fontSize: 16,
  }),
}));

export const WarPhotoContainer = styled("div")(({ theme, className }) => ({
  marginTop: 31,
  maxWidth: "48%",
  alignItems: "flex-end",
  flexDirection: "column",
  display: className?.includes("hidden") ? "none" : "flex",
  ...desktop_plus({
    maxWidth: "100%",
    marginLeft: 30,
    marginTop: 71,
  }),
}));

export const WarTextBlock = styled("div")(({ theme, className }) => ({
  display: "flex",
  flexDirection: "column",
  ...tablet({
    alignItems: "flex-end",
  }),
  ...desktop({
    alignItems: "center",
  }),
}));

export const TheWarContainer = styled("div")(({ theme, className }) => ({
  display: "flex",
  flexDirection: "column",
  ...tablet({}),
  ...desktop_plus({
    flexDirection: "row",
  }),
}));

export const TextWrapper = styled("div")(({ theme, className }) => ({
  ...desktop_plus({ maxWidth: 520 }),
}));

export const WarTextContent = styled("div")(({ theme, className }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: 20,
  width: "100%",
  ...tablet({
    paddingTop: 52,
  }),
  ...desktop_plus({
    flexDirection: "row",
    justifyContent: "space-between",
  }),
}));

export const WarPhotos = styled("div")(({ theme, className }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  ...desktop({}),
}));

export const WarPhoto = styled("img")(({ theme, className }) => ({
  width: 155,
  height: className?.includes("second-pic") ? 223 : 122,
  marginBottom: 10,
  ...tablet({
    width: 255,
    height: className?.includes("second-pic") ? 313 : 188,
  }),
  ...desktop({
    marginBottom: 15,
    width: 300,
    height: className?.includes("second-pic") ? 448 : 237,
  }),
}));

export const WarPhotoDescription = styled("p")(({ theme, className }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 1.6,
  textAlign: "right",
  maxWidth: 155,
  ...tablet({
    fontSize: 16,
  }),
}));
