import { useMediaQuery } from "@mui/material";
import React from "react";
import { DESKTOP_XL_MEDIA_QUERY } from "../../constants/breakpoints";
import { ColoredText } from "../common/styles";
import Container from "../Container";
import {
  TextWrapper,
  TheWarContainer,
  WarPhoto,
  WarPhotoContainer,
  WarPhotoDescription,
  WarPhotos,
  WarText,
  WarTextBlock,
  WarTextContent,
  WarTitle,
} from "./styles";

const TheWar = () => {
  const isDesktop = useMediaQuery(DESKTOP_XL_MEDIA_QUERY);
  return (
    <Container>
      <WarTitle>
        After the <ColoredText>Russian attack on Ukraine,</ColoredText>
      </WarTitle>
      <TheWarContainer>
        <WarTextBlock>
          <WarTitle className={"second-part"}>
            we found ourselves in a difficult situation.
          </WarTitle>
          <WarTextContent>
            <TextWrapper>
              <WarText>
                Our team spent most of the time in bunkers, while ours cities
                were destroyed by Russian missiles. Most of us live in Kyiv and
                the Kyiv region, which was under the occupation of the Russian
                army between February and April 2022.
              </WarText>
              <WarText>
                All Ukrainian cities are destroyed by Russian missiles everyday.
                The enemy is hitting civilian infrastructure and committing a
                real genocide of the Ukrainian people.
              </WarText>
              <WarText>
                To distract from this, part of the team started writing
                educational toys. This is how the OmiPlay team was formed.
              </WarText>
              <WarText>
                So far, we have already managed to release our first game -
                SudokuPro.app, and started working on the next project.
              </WarText>
            </TextWrapper>
            <WarPhotoContainer className={!isDesktop ? "hidden" : "showed"}>
              <WarPhoto
                loading='lazy'
                src='/images/Img_Borodyanka.webp'
                alt='Borodyanka'
              />
              <WarPhotoDescription>
                Residential Building after Russian bombs. <br />
                Borodyanka, Kyiv region, Ukraine
              </WarPhotoDescription>
            </WarPhotoContainer>
          </WarTextContent>
        </WarTextBlock>
        <WarPhotos>
          <WarPhotoContainer className={isDesktop ? "hidden" : "showed"}>
            <WarPhoto
              loading='lazy'
              src='/images/Img_Borodyanka.webp'
              alt='Borodyanka'
            />
            <WarPhotoDescription>
              Residential Building after Russian bombs. <br />
              Borodyanka, Kyiv region, Ukraine
            </WarPhotoDescription>
          </WarPhotoContainer>
          <WarPhotoContainer>
            <WarPhoto
              loading='lazy'
              className={"second-pic"}
              src='/images/Img_Square.webp'
              alt='Square'
            />
            <WarPhotoDescription>
              Protective Structure on Independence Square in Kyiv, Ukraine
            </WarPhotoDescription>
          </WarPhotoContainer>
        </WarPhotos>
      </TheWarContainer>
    </Container>
  );
};

export default TheWar;
