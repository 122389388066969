import type { NextPage } from "next";
import React from "react";
import Head from "next/head";

import { PopUpT as Props } from "../src/types/popup";
import TopContent from "../src/components/TopContent";
import AboutOmiPlay from "../src/components/AboutOmiPlay";
import TheWar from "../src/components/TheWar";
import SudokuPro from "../src/components/SudokuPro";
import SpiderSolitaire from "../src/components/SpiderSolitaire";

const Home: NextPage<Props> = ({ setPopup, popup }) => {
  return (
    <div>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        ></meta>
        <meta name='description'></meta>
        <meta
          name='keywords'
          content='free sudoku, puzzles sudoku, game of sudoku, play sudoku online'
        />
        <title>OmiPlay</title>
      </Head>
      <>
        <TopContent />
        <AboutOmiPlay />
        <TheWar />
        <SudokuPro />
        <SpiderSolitaire />
      </>
    </div>
  );
};

export default Home;
