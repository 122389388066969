import { useMediaQuery } from "@mui/material";
import Image from "next/image";
import React from "react";
import { PHONE_TABLET_MEDIA_QUERY } from "../../constants/breakpoints";

import {
  SudokuMainImg,
  SudokuImgContainer,
  SudokuText,
  SudokuTextWrapper,
  SudokuTitle,
  SudokuWrapper,
  SudokuContainer,
  SudokuPlayBtn,
  SudokuPlayContainer,
  OurGamesText,
  StyledAppIcon,
} from "./styles";
import Link from "next/link";

const SudokuPro: React.FC = () => {
  const isMobile = useMediaQuery(PHONE_TABLET_MEDIA_QUERY);
  return (
    <>
      <OurGamesText>Our games</OurGamesText>
      <SudokuWrapper>
        <SudokuContainer>
          <Image
            width={isMobile ? "182" : "252"}
            height={isMobile ? "182" : "252"}
            loading="lazy"
            src="/images/Sudoku_icon.png"
            alt="Sudoku icon"
          />
          <SudokuTextWrapper>
            <SudokuTitle>Sudoku.Pro App</SudokuTitle>
            <SudokuText>
              A classic Sudoku game for beginners to advanced players. The game
              will help you spend time with pleasure. Train your brain and logic
              with SudokuPro.app
            </SudokuText>
            <SudokuPlayContainer>
              <Link
                href="https://sudokupro.app/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <SudokuPlayBtn aria-label="Play online">
                  PLAY ONLINE
                </SudokuPlayBtn>
              </Link>
              <Link
                href="https://apps.apple.com/ua/app/sudoku-pro-play-offline/id1661946513"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <StyledAppIcon
                  loading="lazy"
                  width="190"
                  height="56"
                  src="/images/App_Store_badge.png"
                  alt="AppStore"
                />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.omiplay.sudokupro"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <StyledAppIcon
                  loading="lazy"
                  width="190"
                  height="56"
                  src="/images/Google_Play_badge.png"
                  alt="GooglePlay"
                />
              </Link>
            </SudokuPlayContainer>
          </SudokuTextWrapper>
        </SudokuContainer>
        <SudokuImgContainer>
          {!isMobile ? (
            <SudokuMainImg
              loading="lazy"
              src="/images/sudoku_main_mob.webp"
              alt="Sudoku Big img"
            />
          ) : (
            <SudokuMainImg
              loading="lazy"
              src="/images/sudoku_main_desc.webp"
              alt="Sudoku Big img"
            />
          )}
        </SudokuImgContainer>
      </SudokuWrapper>
    </>
  );
};

export default SudokuPro;
