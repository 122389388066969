import { Button, styled } from "@mui/material";
import {
  desktop,
  desktop_plus,
  phone_tablet,
  tablet,
} from "../../constants/breakpoints";
import COLORS from "../../constants/colors";

export const SudokuWrapper = styled("div")(({ theme, className }) => ({
  background: COLORS.MAIN,
  paddingTop: 63,
  ...tablet({
    paddingTop: 118,
  }),
}));

export const SudokuContainer = styled("div")(({ theme, className }) => ({
  padding: "0 25px",
  margin: "0 auto",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  ...tablet({
    flexDirection: "row",
    justifyContent: "flex-start",
  }),
  ...desktop({
    flexWrap: "nowrap",
  }),
  ...desktop_plus({
    padding: 0,
    width: "calc((100%/6) * 4)",
  }),
}));

export const StyledAppIcon = styled("img")(({ theme, width, height }) => ({
  width: width,
  height: height,
  cursor: "pointer",
}));

export const SudokuText = styled("p")(({ theme, className }) => ({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: 1.4,
  textTransform: "uppercase",
  color: COLORS.WHITE,
  textAlign: "center",
  marginBottom: 31,
  ...tablet({
    textAlign: "left",
  }),
}));

export const SudokuTitle = styled("h3")(({ theme, className }) => ({
  fontWeight: 700,
  fontSize: 30,
  lineHeight: 1.2,
  textTransform: "uppercase",
  color: COLORS.WHITE,
  textAlign: "center",
  marginBottom: 20,
  marginTop: 0,
  ...tablet({
    marginBottom: 30,
    textAlign: "left",
    fontSize: 48,
    lineHeight: 1.3,
  }),
}));

export const SudokuTextWrapper = styled("div")(({ theme, className }) => ({
  textAlign: "center",
  ...tablet({
    textAlign: "left",
  }),
}));

export const SudokuMainImg = styled("img")(({ theme, width, height }) => ({
  width: "100%",
}));

export const SudokuImgContainer = styled("div")(({ theme, className }) => ({
  lineHeight: 0.5,
  margin: "34px auto 0",
  ...desktop_plus({
    margin: "68px auto 0",
    padding: 0,
    width: "calc((100%/6) * 4)",
  }),
}));

export const SudokuPlayContainer = styled("div")(({ theme, className }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 30,
  ...tablet({
    gap: 21,
    flexDirection: "row",
  }),
}));

export const SudokuPlayBtn = styled(Button)(({ theme, className }) => ({
  color: COLORS.WHITE,
  border: `1px solid ${COLORS.WHITE}`,
  borderRadius: 0,
  width: 190,
  height: 56,
}));

export const OurGamesText = styled("h2")(({ theme, className }) => ({
  fontWeight: 700,
  fontSize: 30,
  lineHeight: 1.2,
  textAlign: "center",
  textTransform: "uppercase",
  fontStyle: "normal",
  color: COLORS.MAIN,
  margin: 30,
  ...tablet({
    fontSize: 60,
  }),
}));
