import { styled } from "@mui/material";
import {
  desktop,
  desktop_plus,
  tablet,
  phone_tablet,
} from "../../constants/breakpoints";
import COLORS from "../../constants/colors";

export const MainText = styled("h1")(({ theme, className }) => ({
  fontWeight: 700,
  fontSize: 50,
  lineHeight: 1.2,
  textTransform: "uppercase",
  position: "relative",
  userSelect: "none",
  "&:before": {
    position: "absolute",
    content: "attr(data-text)",
    color: COLORS.WHITE,
    background: COLORS.BLACK,
    clipPath: "circle(20px at var(--x,-100%) var(--y,-100%))",
  },
  ...tablet({
    fontSize: 120,
  }),
}));

export const ColoredText = styled("div")(({ theme, className }) => ({
  color: COLORS.MAIN,
  position: "relative",
  display: "inline-block",
  marginLeft: 10,
}));

export const MetaballsWrapper = styled("div")(({ theme, className }) => ({
  position: "absolute",
  zIndex: -1,
  right: 0,
  left: 0,
  top: 50,
  width: "100%",
  height: 550,
  ...phone_tablet({
    height: 400,
  }),
  ...tablet({
    top: 80,
    height: 1150,
  }),
  ...desktop({
    height: 1000,
  }),
  ...desktop_plus({
    height: 950,
  }),
}));

export const IconWrapper = styled("div")(({ theme, className }) => ({
  position: "relative",
  display: "inline-block",
  marginLeft: 10,
}));

export const ScrollBtn = styled("div")(({ theme, className }) => ({
  color: COLORS.MAIN,
  fontWeight: 500,
  fontSize: 24,
  lineHeight: 1.2,
  textTransform: "uppercase",
  marginTop: 20,
  "&:hover": {
    cursor: "pointer",
  },
  ...tablet({
    marginTop: 30,
  }),
}));

export const SubContainer = styled("div")(({ theme, className }) => ({
  margin: "0 auto",
  ...tablet({
    paddingLeft: 300,
  }),
  ...desktop({
    paddingLeft: 450,
  }),
  ...desktop_plus({
    paddingLeft: 350,
  }),
}));

export const SubText = styled("p")(({ theme, className }) => ({
  color: COLORS.GRAY_FOR_TEXT,
  fontSize: 16,
  lineHeight: 1.4,
  maxWidth: 500,
  ...tablet({
    fontSize: 16,
    maxWidth: 450,
  }),
  ...desktop_plus({
    maxWidth: 410,
  }),
}));

export const CreateIcon = styled("div")(({ theme, className }) => ({
  position: "absolute",
  top: 17,
  left: 10,
  width: 19,
  height: 19,
  backgroundImage: `url(/images/Winking_Face_mob.png)`,
  ...tablet({
    backgroundImage: `url(/images/Winking_Face.png)`,
    top: 42,
    left: 24,
    width: 45,
    height: 45,
  }),
}));

export const FireIcon = styled("div")(({ theme, className }) => ({
  position: "absolute",
  top: 26,
  left: 41,
  width: 19,
  height: 19,
  backgroundImage: `url(/images/Fire_mob.png)`,
  ...tablet({
    backgroundImage: `url(/images/Fire.png)`,
    top: 62,
    left: 98,
    width: 45,
    height: 45,
  }),
}));

export const GamesRound = styled("div")(({ theme, className }) => ({
  position: "absolute",
  width: 19,
  height: 19,
  top: 6,
  right: -8,
  backgroundImage: `url(/images/Game_Die.png)`,
  backgroundSize: "cover",
  ...tablet({
    width: 45,
    height: 45,
    top: 8,
    right: -18,
  }),
}));
